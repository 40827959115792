import React, { Fragment } from "react"
import googlePlay from "../../../images/google-play-store.svg"
import appStore from "../../../images/app-store.svg"

export const BannerReverse = ({ banner }) => {
  if (!banner) { return <></>; }

  const { title, description } = banner;

  return (
    <Fragment>
      <div className="header section-header section-header-reverse pb-md-5" id="header-reverse">
        <div className="header section-header-content">
          <div className="container">
            <div className="row">
              <div className="col-12 px-md-5 pb-5 pt-5 text-center">
                <h1 className="text-uppercase">{title}</h1>
                <p className="px-md-4">{description}</p>
                <a
                  className="p-0 my-btn-store m-2"
                  href="https://play.google.com/store/apps/details?id=com.coopzapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mb-0 mt-1" alt="Google Play" src={googlePlay} width="auto" />
                </a>
                <a
                  className="p-0 my-btn-store m-2"
                  href="https://apps.apple.com/app/id1467176993"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mb-0 mt-1" alt="App Store" src={appStore} width="auto" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
